<template>
  <div class="courseManagement">
    <el-scrollbar class="elScroll">
      <template v-if="$route.name == 'courseManagement'">
        <div class="search_sList">
          <div class="top_opera">
            <el-form
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
              label-width="100px"
              label-position="right"
              ref="searchForm"
            >
              <el-form-item label="学校">
                <el-select v-model="formInline.tenantId" placeholder="学校" filterable @change="changeSchEvent">
                  <el-option
                    v-for="item in allSchoolData"
                    :key="item.tenantId"
                    :label="item.tenantName"
                    :value="item.tenantId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="批次">
                <el-select v-model="formInline.admissionBatch" @change="changeTen" filterable placeholder="批次">
                  <el-option
                    v-for="item in schoolBatchData"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="层次">
                <el-select v-model="formInline.eduCategory"  @change="changeTen" placeholder="层次">
                  <el-option v-for="(item, key, index) in $common.getEduCategory" :key="index" :label="item" :value="key"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="专业">
                <el-select v-model="formInline.majorId" placeholder="专业" @focus="getSearchMajor" filterable clearable>
                  <el-option
                    v-for="item in majorNameData"
                    :key="item.majorId"
                    :label="item.majorName"
                    :value="item.majorId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="学期">
                <el-select
                  v-model="formInline.semester"
                  placeholder="学期"
                  clearable
                >
                  <el-option
                    v-for="item in semesterOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="课程名称">
                <el-input
                  v-model="formInline.name"
                  placeholder="课程名称"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="教学计划名称">
                <el-input
                  v-model="formInline.teachingPlanName"
                  placeholder="教学计划名称"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="省份">
                <el-select v-model="formInline.provinceId" placeholder="省份" filterable clearable>
                  <el-option
                    v-for="item in proviceData"
                    :key="item.code"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="学习学期">
                <el-select v-model="formInline.semesterName" placeholder="学习学期" filterable clearable>
                  <el-option
                    v-for="item in semesterData"
                    :key="item.semesterId"
                    :label="item.semesterName"
                    :value="item.semesterName">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="得分显示">
                <el-select v-model="formInline.scoreType" clearable>
                  <el-option
                    v-for="(item, key) in $common.scoreTypeData"
                    :key="key"
                    :label="item"
                    :value="key">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="有无课件">
                <el-select v-model="formInline.isCourseware" clearable>
                  <el-option
                    v-for="(item, key) in switchArr"
                    :key="key+item"
                    :label="item"
                    :value="key">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="课件总时长">
                <div class="timeTotal">
                  <el-input
                  v-model="formInline.minLength"
                  onkeypress='return( /[\d]/.test(String.fromCharCode(event.keyCode)))'
                ></el-input>
                <div>   ——</div>

                <el-input
                  v-model="formInline.maxLength"
                  onkeypress='return( /[\d]/.test(String.fromCharCode(event.keyCode)))'
                ></el-input>
                <span> 分钟</span>
                </div>

              </el-form-item>

              <el-form-item label="有无教辅材料">
                <el-select v-model="formInline.isEbook" clearable>
                  <el-option
                    v-for="(item, key) in switchArr"
                    :key="key+item"
                    :label="item"
                    :value="key">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="有无题库">
                <el-select v-model="formInline.isQuestionBank" clearable>
                  <el-option
                    v-for="(item, key) in switchArr"
                    :key="key+item"
                    :label="item"
                    :value="key">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="有无试卷">
                <el-select v-model="formInline.isExamPaper" clearable>
                  <el-option
                    v-for="(item, key) in switchArr"
                    :key="key+item"
                    :label="item"
                    :value="key">
                  </el-option>
                </el-select>
              </el-form-item>


            </el-form>
            <div class="btn_area">
              <el-button class="mainBtn" @click="onSubmit" plain>搜索</el-button>
              <el-button type="info" plain @click="resetEvent">重置</el-button>
            </div>
          </div>
        </div>
        <div class="table_sList">
          <div class="opera_btnArea">
            <el-button class="mainBtn" @click="exportNewEvent" plain>导出</el-button>
            <el-button class="mainBtn" @click="batchDelete" plain>批量删除</el-button>
            <!-- <el-button class="mainBtn" @click="batchCancelEvent" plain>批量取消匹配</el-button>
            <el-button class="mainBtn" @click="batchChangeCWEvent" plain>批量匹配课件</el-button> -->
          </div>
          <el-table
            :data="tableData"
            stripe
            border
            ref="examTable"
            style="width: 100%"
            align="center"
            row-key="planCourseId"
            @selection-change="handleSelectionChange"
          >
            <template slot="empty">
              <div v-loading="loading" class="emptydiv">暂无数据</div>
            </template>
            <el-table-column
              align="center"
              type="selection"
              :reserve-selection="true"
              width="40"
            >
            </el-table-column>
            <el-table-column prop="province" label="省份" align="center" width="100"></el-table-column>
            <el-table-column prop="tenantName" label="学校" align="center" width="180"></el-table-column>
            <el-table-column prop="admissionBatch" label="批次" align="center" width="100"></el-table-column>
            <el-table-column prop="eduCategory" label="层次" align="center" width="100">
              <template slot-scope="scope">
              {{$common.getEduCategory[scope.row.eduCategory]}}
            </template>
            </el-table-column>
            <el-table-column
              prop="majorName"
              label="专业名称"
              align="center"
              width="180"
            >
              <template slot-scope="scope">
                <el-tooltip
                  :content="scope.row.majorName"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                >
                  <div class="ellipsis">{{ scope.row.majorName }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="teachingPlanName" label="教学计划" align="center" width="180"></el-table-column>
            <el-table-column
              prop="name"
              label="课程名称"
              align="center"
              width="100"
            >
              <template slot-scope="scope">
                <el-tooltip
                  :content="scope.row.name"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                >
                  <div class="ellipsis">{{ scope.row.name }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="totalLengthStr"
              label="课件总时长（小时）"
              align="center"
              width="180"
            >
              <!-- <template slot-scope="scope">
                <el-tooltip
                  :content="scope.row.coursewareName"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                >
                  <div
                    class="ellipsis mainColor"
                    @click="showCourse('courseware', scope.row)"
                  >
                    {{ scope.row.coursewareName || "暂无" }}
                  </div>
                </el-tooltip>
              </template> -->
            </el-table-column>
            <el-table-column
              prop="courseName"
              label="匹配课程名称"
              align="center"
              width="160"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.courseName">
                  <el-tooltip
                    :content="scope.row.courseName"
                    placement="top"
                    effect="light"
                    popper-class="pubToop"
                  >
                    <!-- <p
                      class="ellipsis mainColor"
                      @click="showCourse('coursemate', scope.row)"
                    >
                      {{ scope.row.courseName }}
                    </p> -->
                    <span>{{ scope.row.courseName }}</span>
                  </el-tooltip>
                </div>
                <div v-else class="coursemate">
                  <div class="ellipsis mainColor">
                    <el-tag
                      size="small"
                      @click="showCourse('coursemate', scope.row)"
                      >选择课程</el-tag
                    >
                  </div>
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop="subjectId"
              label="科目"
              align="center"
              width="180"
            >
              <template slot-scope="scope">
                <el-tooltip
                  :content="String(scope.row.subjectId)"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                >
                  <div class="ellipsis">{{ scope.row.subjectId || "/" }}</div>
                </el-tooltip>
              </template>
            </el-table-column>-->
            <el-table-column
              prop="courseNature"
              label="课程性质"
              align="center"
              :formatter="regCourseNature"
            ></el-table-column>
            <el-table-column
              prop="courseType"
              label="课程类型"
              align="center"
              :formatter="regCourseType"
            ></el-table-column>
            <el-table-column
              prop="semester"
              label="学期"
              align="center"
              width="70"
            ></el-table-column>
            <el-table-column
              prop="period"
              label="学时"
              align="center"
            ></el-table-column>
            <!-- <el-table-column prop="admissionBatch" label="批次" align="center">
            </el-table-column>-->
            <el-table-column
              prop="scoreType"
              label="得分显示"
              align="center"
              :formatter="regScoreType"
            ></el-table-column>
            <el-table-column
              prop="coursewareName"
              label="课件名称"
              align="center"
              width="180"
            >
              <template slot-scope="scope">
                <el-tooltip
                  :content="scope.row.coursewareName"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                >
                  <div
                    class="ellipsis mainColor"
                    @click="showCourse('courseware', scope.row)"
                  >
                    {{ scope.row.coursewareName || "暂无" }}
                  </div>
                </el-tooltip>
                <!-- <span @click="showCourse('courseware', scope.row)" class="mainColor">{{scope.row.coursewareName}}</span> -->
              </template>
            </el-table-column>


            <!-- <el-table-column prop="questionBankIds" label="题库ID" align="center">
              <template slot-scope="scope">
                <el-tooltip
                  :content="scope.row.questionBankIds"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                >
                  <div
                    @click="showCourse('question', scope.row)"
                    class="ellipsis mainColor"
                  >
                    {{ scope.row.questionBankIds || "/" }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>-->
            <!-- <el-table-column
              prop="questionBankName"
              label="题库名称"
              align="center"
              width="180"
            >
              <template slot-scope="scope">
                <el-tooltip
                  :content="scope.row.questionBankName"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                >
                  <div
                    @click="showCourse('question', scope.row)"
                    class="ellipsis mainColor"
                  >
                    {{ scope.row.questionBankName || "暂无" }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column> -->
            <!-- <el-table-column prop="ebookIds" label="教辅材料ID" align="center">
              <template slot-scope="scope">
                <el-tooltip
                  :content="scope.row.ebookIds"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                >
                  <div
                    @click="showCourse('ebook', scope.row)"
                    class="ellipsis mainColor"
                  >
                    {{ scope.row.ebookIds || "/" }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>-->
            <el-table-column
              prop="ebookName"
              label="教辅材料书名"
              align="center"
              width="180"
            >
              <template slot-scope="scope">
                <el-tooltip
                  :content="scope.row.ebookName"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                >
                  <div
                    @click="showCourse('ebook', scope.row)"
                    class="ellipsis mainColor"
                  >
                    {{ scope.row.ebookName || "暂无" }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="topicNum"
              label="题目数量"
              align="center"
              width="70"
            >
              <template slot-scope="scope">
                <!-- <el-tooltip
                  :content="scope.row.questionBankName"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                > -->
                  <div
                    @click="showCourse('question', scope.row)"
                    class="ellipsis mainColor"
                  >
                    {{ scope.row.topicNum || "暂无" }}
                  </div>
                <!-- </el-tooltip> -->
              </template>
            </el-table-column>
            <el-table-column
              prop="examPaperNum"
              label="试卷数量"
              align="center"
              width="70"
            >
              <template slot-scope="scope">
                <div
                  @click="showCourse('examnum', scope.row)"
                  class="ellipsis mainColor"
                >
                  {{ scope.row.examPaperNum || "暂无" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="updateTime"
              label="更新时间"
              align="center"
              width="120"
            >
              <template slot-scope="scope">
                <span>{{ $way.timestampReturnDate(scope.row.updateTime) || '/' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="
                $isShowModle('course_layout_edit') ||
                  $isShowModle('course_layout_del')
              "
              fixed="right"
              label="操作"
              align="center"
              width="100"
            >
              <template slot-scope="scope">
                <el-button
                  size="small"
                  type="text"
                  @click="handleCourse(scope.row)"
                  v-if="$isShowModle('course_layout_edit')"
                  >编辑</el-button
                >
                <el-button
                  size="small"
                  type="text"
                  @click="delCourse(scope.row)"
                  v-if="$isShowModle('course_layout_del')"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <Pagination @changePage="changePage" :childMsg="childMsg"></Pagination>
        </div>
        <Keydown @keydownEvent='handleKeydown'></Keydown>
        <!-- 批量导出课程安排 -->
        <dialogDiy
          :isShow="isShowOut"
          :dialogDiyData="dialogOutDiyData"
          @dialogEvent="dialogOutEvent"
        >
          <div slot="dialogContent" class="dia_operas">
            <img class="errIcon" src="@/assets/img/warn.png" alt />
            <p>是否导出当前教学计划的课程安排</p>
            <div class="btn_area">
              <el-button @click="cancelEvent">关闭</el-button>
              <el-button class="mainBtn" @click="exportEvents">确定</el-button>
            </div>
          </div>
        </dialogDiy>

        <!-- 点击课件名称或者是题库名称显示的弹出框 -->
        <dialogDiy
          :isShow="isShowCourseware"
          :dialogDiyData="dialogCoursewareDiyData"
          @dialogEvent="dialogCoursewareEvent"
        >
          <div slot="dialogContent" class="opera_area">
            <div
              class="search_erea"
              v-if="
                types != 'examnum' && types != 'coursemate' && types != 'courseware'
              "
            >
              <el-input
                placeholder="请输入名称、关键字"
                prefix-icon="el-icon-search"
                v-model="inputKey"
              ></el-input>
              <!-- <el-input
                v-if="activeQuestion == 1"
                placeholder="请输入学校名称"
                prefix-icon="el-icon-search"
                style="margin-left: 10px"
                v-model="schoolval"
              ></el-input>-->
              <el-button
                type="primary"
                @click="toDoSth(vm)"
                style="margin-left: 10px"
                >搜索</el-button
              >
            </div>

            <template v-if="types == 'courseware'">
              <div style="padding: 20px">
                <el-row>
                  <el-col :span="19">
                    <div class>
                      <el-form :inline="true" label-width="78px" ref="searchForm">
                        <!-- <el-form-item label="课程名称">
                          <el-input
                            placeholder="请输入课程名称"
                            prefix-icon="el-icon-search"
                            clearable
                            v-model="kechengName"
                          ></el-input>
                        </el-form-item> -->

                        <el-form-item label="课件名称">
                          <el-input
                            placeholder="请输入课件名称"
                            prefix-icon="el-icon-search"
                            clearable
                            v-model="inputKey"
                          ></el-input>
                        </el-form-item>
                      </el-form>
                    </div>
                  </el-col>
                  <el-col :span="5">
                    <div class>
                      <el-button
                        type="primary"
                        @click="toDoSth(vm)"
                        style="margin-left: 10px"
                        >搜索</el-button
                      >
                      <el-button type="info" @click="resetCourseWare" plain>重置</el-button>
                      <!-- @click="resetEvent" -->
                    </div>
                  </el-col>
                </el-row>
              </div>
              <el-table
                :data="newTableData"
                stripe
                border
                style="width: 100%"
                max-height="450"
                align="center"
              >
                <el-table-column
                  prop="courseClassifyName"
                  label="课程分类"
                  align="center"
                  width="100"
                >
                  <template slot-scope="scope">
                    <el-tooltip
                      :content="scope.row.courseClassifyName"
                      placement="top"
                      effect="light"
                      popper-class="pubToop"
                    >
                      <div class="ellipsis">
                        {{ scope.row.courseClassifyName || "/" }}
                      </div>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="courseName"
                  label="课程名称"
                  align="center"
                  width="120"
                >
                  <template slot-scope="scope">
                    <el-tooltip
                      :content="scope.row.courseName"
                      placement="top"
                      effect="light"
                      popper-class="pubToop"
                    >
                      <div class="ellipsis">{{ scope.row.courseName }}</div>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="id"
                  label="课件ID"
                  align="center"
                  width="60"
                ></el-table-column>
                <el-table-column
                  prop="coursewareName"
                  label="课件名称"
                  align="center"
                  width="120"
                >
                  <template slot-scope="scope">
                    <el-tooltip
                      :content="scope.row.coursewareName"
                      placement="top"
                      effect="light"
                      popper-class="pubToop"
                    >
                      <div class="ellipsis">{{ scope.row.coursewareName }}</div>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="school"
                  label="来源学校"
                  align="center"
                  width="130"
                ></el-table-column>
                <el-table-column prop="mediaUri" label="在线预览" align="center">
                  <template slot-scope="scope">
                    <span class="clickWatch" @click="previewEvent(scope.row)"
                      >点击查看</span
                    >
                  </template>
                </el-table-column>
                <!-- <el-table-column
                  prop="lessonNum"
                  label="课时数"
                  align="center"
                  width="80">
                </el-table-column>-->
                <el-table-column
                  prop="sectionNum"
                  label="章节数"
                  align="center"
                ></el-table-column>
                <el-table-column prop="author" label="课件作者" align="center">
                  <template slot-scope="scope">
                    <el-tooltip
                      :content="scope.row.author"
                      placement="top"
                      effect="light"
                      popper-class="pubToop"
                    >
                      <div class="ellipsis">{{ scope.row.author }}</div>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column prop="isEnable" label="是否启用" align="center">
                  <template slot-scope="scope">
                    <el-checkbox
                      @change="changeEvent(scope.row, scope.$index)"
                      v-model="scope.row.isEnable"
                    ></el-checkbox>
                  </template>
                </el-table-column>
                <!-- <el-table-column
                  align="center"
                  type="selection"
                  width="55">
                </el-table-column>-->
              </el-table>

              <Pagination
                @changePage="changeCourseWarePage"
                :childMsg="coursewareChildMsg"
              ></Pagination>
            </template>
            <!-- 课程匹配 -->
            <template v-if="types == 'coursemate'">
              <div class="search_erea">
                <el-input
                  placeholder="请输入课程名称"
                  prefix-icon="el-icon-search"
                  v-model="formCourseInline.name"
                ></el-input>
                <el-button
                  type="primary"
                  @click="getCourseNewData(vm)"
                  style="margin-left: 10px"
                  >搜索</el-button
                >
              </div>
              <el-button
                type="primary"
                @click="addNewCoursemate(vm)"
                style="margin-bottom: 10px"
                size="small"
                >新建课程</el-button
              >
              <el-table
                :data="newTableData"
                stripe
                border
                style="width: 100%"
                max-height="370"
                align="center"
              >
                <el-table-column prop="name" label="课程名称" align="center">
                  <template slot-scope="scope">
                    <el-tooltip
                      :content="scope.row.name"
                      placement="top"
                      effect="light"
                      popper-class="pubToop"
                    >
                      <div class="ellipsis">{{ scope.row.name }}</div>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="questionBankNum"
                  label="题库数量"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="coursewareNum"
                  label="课件数量"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="examPaperNum"
                  label="试卷数量"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="ebookNum"
                  label="教辅材料数量"
                  align="center"
                ></el-table-column>
                <el-table-column prop="isEnable" label="是否启用" align="center">
                  <template slot-scope="scope">
                    <el-checkbox
                      @change="changeEvent(scope.row, scope.$index)"
                      v-model="scope.row.isEnable"
                    ></el-checkbox>
                  </template>
                </el-table-column>
                <!-- <el-table-column
                  align="center"
                  type="selection"
                  width="55">
                </el-table-column>-->
              </el-table>
              <Pagination
                @changePage="changeCoursePage"
                :childMsg="courseChildMsg"
              ></Pagination>
            </template>
            <template v-else-if="types == 'question'">
              <el-tabs v-model="activeQuestion">
                <el-tab-pane label="公共" name="0">
                  <el-table
                    :data="newTableData"
                    stripe
                    border
                    style="width: 100%"
                    max-height="400"
                    align="center"
                  >
                    <el-table-column
                      prop="questionBankName"
                      label="题库名称"
                      align="center"
                      width="180"
                    >
                      <template slot-scope="scope">
                        <el-tooltip
                          :content="scope.row.questionBankName"
                          placement="top"
                          effect="light"
                          popper-class="pubToop"
                        >
                          <div class="ellipsis">
                            {{ scope.row.questionBankName }}
                          </div>
                        </el-tooltip>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="questionCount"
                      label="题目数量"
                      align="center"
                    ></el-table-column>
                    <el-table-column prop="isUsing" label="是否启用" align="center">
                      <template slot-scope="scope">
                        <el-checkbox
                          @change="changeEvent(scope.row, scope.$index)"
                          v-model="scope.row.isUsing"
                        ></el-checkbox>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-tab-pane>
                <el-tab-pane label="学校私库" name="1">
                  <el-table
                    :data="newTablePrivateData"
                    stripe
                    border
                    style="width: 100%"
                    max-height="400"
                    align="center"
                  >
                    <el-table-column
                      prop="questionBankName"
                      label="题库名称"
                      align="center"
                      width="180"
                    >
                      <template slot-scope="scope">
                        <el-tooltip
                          :content="scope.row.questionBankName"
                          placement="top"
                          effect="light"
                          popper-class="pubToop"
                        >
                          <div class="ellipsis">
                            {{ scope.row.questionBankName }}
                          </div>
                        </el-tooltip>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="tenantName"
                      label="学校名称"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      prop="questionCount"
                      label="题目数量"
                      align="center"
                    ></el-table-column>
                    <el-table-column prop="isUsing" label="是否启用" align="center">
                      <template slot-scope="scope">
                        <el-checkbox
                          @change="changeEvent(scope.row, scope.$index)"
                          v-model="scope.row.isUsing"
                        ></el-checkbox>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-tab-pane>
              </el-tabs>
            </template>
            <template v-else-if="types == 'ebook'">
              <el-table
                :data="newTableData"
                stripe
                border
                style="width: 100%"
                max-height="450"
                align="center"
              >
                <el-table-column
                  prop="courseClassifyName"
                  label="课程分类"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-tooltip
                      :content="scope.row.courseClassifyName"
                      placement="top"
                      effect="light"
                      popper-class="pubToop"
                    >
                      <div class="ellipsis">
                        {{ scope.row.courseClassifyName || "/" }}
                      </div>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <!-- <el-table-column
                  prop="tenantName"
                  label="科目"
                  align="center"
                  show-overflow-tooltip>
                </el-table-column>-->
                <el-table-column prop="courseName" label="课程名称" align="center">
                  <template slot-scope="scope">
                    <el-tooltip
                      :content="scope.row.courseName"
                      placement="top"
                      effect="light"
                      popper-class="pubToop"
                    >
                      <div class="ellipsis">{{ scope.row.courseName }}</div>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="id"
                  label="教辅材料编号"
                  align="center"
                ></el-table-column>
                <el-table-column prop="name" label="教辅材料名称" align="center">
                  <template slot-scope="scope">
                    <el-tooltip
                      :content="scope.row.name"
                      placement="top"
                      effect="light"
                      popper-class="pubToop"
                    >
                      <div class="ellipsis">{{ scope.row.name }}</div>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column prop="fileUrl" label="在线预览" align="center">
                  <template slot-scope="scope">
                    <span class="clickWatch" @click="ebookPreview(scope.row)"
                      >点击查看</span
                    >
                  </template>
                </el-table-column>
                <el-table-column prop="author" label="作者" align="center">
                  <template slot-scope="scope">
                    <el-tooltip
                      :content="scope.row.author"
                      placement="top"
                      effect="light"
                      popper-class="pubToop"
                    >
                      <div class="ellipsis">{{ scope.row.author }}</div>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column prop="isbn" label="出版号" align="center">
                  <template slot-scope="scope">
                    <el-tooltip
                      :content="scope.row.isbn"
                      placement="top"
                      effect="light"
                      popper-class="pubToop"
                    >
                      <div class="ellipsis">{{ scope.row.isbn || "/" }}</div>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column prop="press" label="出版社" align="center">
                  <template slot-scope="scope">
                    <el-tooltip
                      :content="scope.row.press"
                      placement="top"
                      effect="light"
                      popper-class="pubToop"
                    >
                      <div class="ellipsis">{{ scope.row.press || "/" }}</div>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column prop="isEnable" label="是否启用" align="center">
                  <template slot-scope="scope">
                    <el-checkbox
                      @change="changeEvent(scope.row, scope.$index)"
                      v-model="scope.row.isEnable"
                    ></el-checkbox>
                  </template>
                </el-table-column>
              </el-table>
            </template>
            <template v-else-if="types == 'examnum'">
              <div class="search_erea">
                <el-input
                  placeholder="请输入学校名称"
                  prefix-icon="el-icon-search"
                  v-model="inputKey"
                ></el-input>
                <el-button
                  type="primary"
                  @click="toDoSth(vm)"
                  style="margin-left: 10px"
                  >搜索</el-button
                >
              </div>
              <el-table
                :data="newTableData"
                stripe
                border
                style="width: 100%"
                max-height="450"
                align="center"
              >
                <el-table-column
                  prop="tenantName"
                  label="来源"
                  align="center"
                  width="180"
                >
                  <template slot-scope="scope">
                    <el-tooltip
                      :content="scope.row.tenantName"
                      placement="top"
                      effect="light"
                      popper-class="pubToop"
                    >
                      <div class="ellipsis">{{ scope.row.tenantName }}</div>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="examPaperCount"
                  label="试卷数量"
                  align="center"
                ></el-table-column>

                <el-table-column prop="isUsing" label="是否启用" align="center">
                  <template slot-scope="scope">
                    <el-checkbox
                      v-model="scope.row.isUsing"
                      :disabled="
                        scope.row.sourceType == 0 || scope.row.sourceId == tenantId
                      "
                    ></el-checkbox>
                  </template>
                </el-table-column>
              </el-table>
            </template>
            <div class="btn_area">
              <el-button @click="closeCourse">关闭</el-button>
              <el-button class="mainBtn" @click="saveEvent">保存</el-button>
            </div>
          </div>
        </dialogDiy>
        <dialogDiy
          :isShow="isShow"
          :dialogDiyData="dialogDiyData"
          @dialogEvent="dialogDiyEvent"
        >
          <div slot="dialogContent">
            <div class="dia_opera_area">
              <div class="opera_left">
                <img src="@/assets/img/file.png" />
                <span>课程安排样例</span>
                <el-button class="mainBtn" @click="downLoadMolde"
                  >点击下载</el-button
                >
              </div>
              <div class="opera_right">
                <div class="bor" @click="getLocalPic" v-if="!excelUrl">
                  <img src="@/assets/img/adds.png" />
                  <span>课程安排样例</span>
                </div>
                <div class="bor noBorder" @click="getLocalPic" v-else>
                  {{ showFileName }}
                </div>
                <el-button class="mainBtn" @click="enterUpload">确认上传</el-button>
              </div>
            </div>
            <!-- <div class="dia_tips">
              <div class="d_tips_head">注意事项</div>
              <div class="d_tips_cont">
                <p>上传的课程安排文件需要符合以下要求</p>
                <p>1.课程安排文件中包含的行数需小于3000行。</p>
                <p>2.课程安排文件必须是有效的Excel文件，也就是说必须是用Excel可以打开的以".xlsx"为扩展名的文件。</p>
                <p>3.教学计划文件内容的第一行为列的标题，固定为“专业名称”，“层次”，“课程名称”，
                “学期”，“学时”，“批次”，“得分显示”。并且列的顺序不能变。</p>
                <p>4. 详细说明，详见【教学计划上传样例】。</p>
              </div>
            </div>-->
          </div>
        </dialogDiy>
        <dialogDiy
          :isShow="isResShow"
          :dialogDiyData="dialogResDiyData"
          @dialogEvent="dialogResDiyEvent"
        >
          <div slot="dialogContent" class="result_area">
            <img src="@/assets/img/enter.png" alt />
            <span>您的请求已提交</span>
            <span>
              稍后请在
              <span @click="jumpTask" class="myWork">我的任务</span>
              中查看处理结果！
            </span>
          </div>
        </dialogDiy>

        <!-- 课程编辑  -->
        <!-- 课程编辑弹窗 -->
        <dialogDiy
          :isShow="isShowInfo"
          :dialogDiyData="dialogDiyCourseData"
          @dialogEvent="dialogDiyCourseEvent"
        >
          <div
            class="info-area opera_area"
            slot="dialogContent"
            style="padding-top: 20px;"
          >
            <el-form
              :inline="true"
              :model="addData"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
            >
              <el-form-item label="课程名称" prop="name">
                <el-input
                  v-model="addData.name"
                  placeholder="课程名称"
                  clearable
                  disabled
                ></el-input>
              </el-form-item>
              <el-form-item label="学分" prop="credit">
                <el-input
                  v-model="addData.credit"
                  placeholder="学分"
                  onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  @blur="addData.credit = $event.target.value"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="课程性质" prop="courseNature">
                <el-select
                  v-model="addData.courseNature"
                  placeholder="课程性质"
                  filterable
                  clearable
                >
                  <el-option label="无" :value="0"></el-option>
                  <el-option label="选修" :value="1"></el-option>
                  <el-option label="必修" :value="2"></el-option>
                  <el-option label="主干课程" :value="3"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="课程类型" prop="courseType">
                <el-select
                  v-model="addData.courseType"
                  placeholder="课程类型"
                  filterable
                  clearable
                >
                  <el-option label="无" :value="0"></el-option>
                  <el-option label="公共课" :value="1"></el-option>
                  <el-option label="专业课" :value="2"></el-option>
                  <el-option label="专业基础课" :value="3"></el-option>
                  <el-option label="实践课" :value="4"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="学期" prop="semester">
                <el-input
                  v-model.number="addData.semester"
                  placeholder="学期"
                  @blur="checkNum(scope.row, $event)"
                  maxlength="2"
                  clearable
                  :disabled="!addData.semesterUpdate"
                ></el-input>
              </el-form-item>
              <p class="tips" v-if="!addData.semesterUpdate">
                （
                <span>*</span> 该课程所属学期已结束，无法更改）
              </p>
              <el-form-item label="学时" prop="period">
                <el-input
                  v-model.number="addData.period"
                  placeholder="学时"
                  clearable
                  maxlength="5"
                  onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  @blur="addData.period = $event.target.value"
                ></el-input>
              </el-form-item>
              <el-form-item label="得分显示" prop="scoreType">
                <el-select
                  v-model="addData.scoreType"
                  placeholder="得分显示"
                  filterable
                  clearable
                >
                  <el-option label="分值" :value="1"></el-option>
                  <el-option label="等级" :value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="及格分数" prop="passScore">
                <el-input
                  v-model="addData.passScore"
                  placeholder="及格分数"
                  onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  maxlength="5"
                  @blur="addData.passScore = $event.target.value"
                  clearable
                  :disabled="!addData.passingScoreUpdate"
                ></el-input>
              </el-form-item>
              <p class="tips" v-if="!addData.passingScoreUpdate">
                （
                <span>*</span> 该课程已有学生学习，无法更改）
              </p>
              <!-- <el-form-item label="是否核心课程">
                <el-input
                  v-model="addData.majorName"
                  placeholder="是否核心课程"
                  clearable></el-input>
              </el-form-item>-->
              <el-form-item label="授课方式" prop="teachingMethod">
                <el-checkbox-group v-model="addData.teachingMethod">
                  <el-checkbox label="0">录播</el-checkbox>
                  <el-checkbox label="1">直播</el-checkbox>
                  <el-checkbox label="2">面授</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-form>
            <div class="btn_areas">
              <el-button @click="dialogDiyCourseEvent">关闭</el-button>
              <el-button class="mainBtn" @click="enterEvent('ruleForm')"
                >保存</el-button
              >
            </div>
            <!-- <div class="info-btn-area">
              <el-button type="primary" @click="enterEvent('ruleForm')">确 定</el-button>
              <el-button @click="dialogDiyCourseEvent">取 消</el-button>
            </div>-->
          </div>
        </dialogDiy>
        <!-- 视频预览 -->
        <player
          v-if="isShowVideo"
          @closeEvent="closeEvent"
          :videoData="videoData"
          :vid="vid"
        ></player>

        <input
          style="display: none"
          type="file"
          @change="picChange()"
          ref="loadPic"
          accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />

        <fileExportNew
          :isShowFile="isShowFile"
          :title="title"
          :txtObj="txtObj"
          :dialogData="dialogFEData"
          @closeFile="downEvent"
          @enter="enterExportEvent"
        >
          <template #exporttips v-if="feType == 'batchDel'">
            <p class="e_f_tips"><span style="color: red;">注:</span>点击确认后系统将为您判断所选课程是否存在学生学习记录，如果所课程已有学生学习，二次确认后学生学习记录将被一并删除。</p>
          </template>
        </fileExportNew>

        <handle
          :isShow="isShowDel"
          :handleText="handleText"
          @handlesEvent="doHandleEvent"
        >
          <div class="btn_area" slot="handle_btn">
            <el-button @click="doHandleEvent">关闭</el-button>
            <el-button type="primary" @click="deleStu">确定</el-button>
          </div>
        </handle>

        <!-- 导出-new -->
        <fileExportNew
          :isShowFile="courseObj.isShowFile"
          :title="courseObj.title"
          :txtObj="txtObj"
          @closeFile="exportNewEvent"
          @enter="enterNewExportEvent"
        ></fileExportNew>
        <!-- 批量取消匹配 -->
        <fileExportNew
          :isShowFile="batchCancelObj.isShowFile"
          :title="batchCancelObj.title"
          :txtObj="batchCancelObj.txtObj"
          :dialogData="batchCancelObj.dialogFEData"
          @closeFile="batchCancelEvent"
          @enter="enterBatchCancelEvent"
        >
          <template #exporttips>
            <div class="ft-area">
              <span class="ft-title">取消匹配项</span>
              <el-checkbox-group v-model="checkList">
                <el-checkbox label=1>课程</el-checkbox>
                <el-checkbox label=2>课件</el-checkbox>
                <el-checkbox label=3>教辅材料</el-checkbox>
              </el-checkbox-group>
            </div>
          </template>
        </fileExportNew>

        <dialogSubmit :isShowSub="isShowSub" @closeSub="closeSub"></dialogSubmit>
      </template>
      <template v-else>
        <router-view></router-view>
      </template>
    </el-scrollbar>
  </div>
</template>

<script>
import way from "@/api/encapsulation";
import dialogDiy from "components/commonModule/dialogDiy";
import Pagination from "components/Pagination";
import player from "components/commonModule/player";
import { excelFileUpload } from "@/api/fileUpload";
import fileExportNew from 'components/commonModule/fileExportNew'
import dialogSubmit from 'components/commonModule/dialogSubmit'
import handle from '@/components/commonModule/handle'
const debounce = way.debounce;
const OSS = require("ali-oss");
export default {
  name: "courseManagement",
  components: {
    dialogDiy,
    player,
    Pagination,
    fileExportNew,
    dialogSubmit,
    handle,
  },
  data() {
    return {
      tenantId: "", // 租户
      activeName: "first",
      formInline: {
        teachingPlanName:null,
        semesterName:null,
        provinceId:null,
        coursewareName: null,
        eduCategory: null,
        id: null,
        isMatching: null,
        majorName: null,
        name: null,
        page: 1,
        pageSize: 10,
        period: null,
        scoreType: null,
        semester: null,
        admissionBatch: null,
        subjectId: null,
        subjectName: null,
        teachingPlanId: null,
        courseNature: null,
        courseType: null,
        tenantId: null,
        majorId: null,
        isCourseware:null,
        minLength:null,
        maxLength:null,
        isEbook:null,
        isQuestionBank:null,
        isExamPaper:null,

      },

      formCourseInline: {
        name: null,
        page: 1,
        pageSize: 10
      },
      formCoursewareInline: {
        page: 1,
        pageSize: 10
      },
      tableData: [],
      majorNameData: [],
      newTableData: [],
      newTablePrivateData: [],
      loading: false,
      newLoading: false,
      types: "courseware",
      inputKey: "",
      kechengName: "",
      isShowCourseware: false,
      dialogCoursewareDiyData: {
        title: "重新选择匹配课件",
        sizeStyle: { width: "900px", height: "750px" }
      },
      isShowOut: false,
      dialogOutDiyData: {
        title: "",
        sizeStyle: { width: "400px", height: "234px" }
      },
      isShow: false,
      isResShow: false,
      isShowPlanEdit: false,
      dialogDiyData: {
        title: "上传课程安排",
        sizeStyle: { width: "640px", height: "350px" }
      },
      dialogResDiyData: {
        title: "",
        sizeStyle: { width: "400px", height: "204px" }
      },
      formExport: {
        teachingPlanId: null,
        // admissionBatch: null,
        // eduCategory: null,
        // name: null,
        // page: 1,
        // pageSize: 20,
        tenantId: null
      },
      vm: this,
      isSearch: false,
      semesterData:[],
      allSchoolData: [],
      schoolBatchData: [],
      proviceData: [],
      excelUrl: "",
      fileName: "",
      showFileName: "",
      form: {
        coursewareId: null,
        coursewareName: null,
        ebookIds: null,
        ebookName: null,
        id: null,
        courName: null,
        subjectId: null,
        questionBankIds: null,
        questionBankName: null
      },
      questionIdArr: [],
      questionNameArr: [],
      ebookIdArr: [],
      examPaperTenantIdArr: [],
      ebookNameArr: [],
      videoData: {},
      vid: "",
      isShowVideo: false,
      semesterOption: [
        {
          value: 1,
          label: 1
        },
        {
          value: 2,
          label: 2
        },
        {
          value: 3,
          label: 3
        },
        {
          value: 4,
          label: 4
        },
        {
          value: 5,
          label: 5
        },
        {
          value: 6,
          label: 6
        },
        {
          value: 7,
          label: 7
        },
        {
          value: 8,
          label: 8
        },
        {
          value: 9,
          label: 9
        },
        {
          value: 10,
          label: 10
        }
      ],
      childMsg: {
        current: 1,
        size: 10,
        total: 1
      },
      // 课程列表分页
      courseChildMsg: {
        current: 1,
        size: 10,
        total: 1
      },
      // 课件列表分页
      coursewareChildMsg: {
        current: 1,
        size: 10,
        total: 1
      },
      rows: "",
      // 题库弹窗的切换
      activeQuestion: "0",
      // 试卷弹窗的切换
      activeExamNum: "0",
      // 课程编辑
      dialogDiyCourseData: {
        title: "编辑课程",
        sizeStyle: { width: "430px", height: "760px" }
      },
      isShowInfo: false,
      rules: {
        name: [{ required: true, message: "请输入课程名称", trigger: "blur" }],
        semester: [{ required: true, message: "请输入学期", trigger: "blur" }],
        period: [{ required: true, message: "请输入学时", trigger: "blur" }],
        credit: [{ required: true, message: "请输入学分", trigger: "blur" }],
        // courseNature: [
        //   { required: true, message: "请输入课程性质", trigger: "blur" },
        // ],
        // courseType: [
        //   { required: true, message: "请输入课程类型", trigger: "blur" },
        // ],
        scoreType: [
          { required: true, message: "请输入得分显示", trigger: "blur" }
        ],
        passScore: [
          { required: true, message: "请输入及格分数", trigger: "blur" }
        ],
        teachingMethod: [
          { required: true, message: "请输入授课方式", trigger: "blur" }
        ]
      },
      addData: {
        courseNature: "",
        courseType: "",
        credit: "",
        name: "",
        period: "",
        semester: "",
        passScore: "",
        picture: "",
        scoreType: "",
        teachingMethod: ["0"],
        teachingPlanId: "",
        tenantId: ""
      },
      newForm: {
        courseId: null,
        coursewareId: null,
        coursewareName: null,
        ebookIds: null,
        ebookName: null,
        teachPlanIds: null,
        type: null
      },
      isShowFile: false,
      title: null,
      txtObj: [],
      feType: null,
      dialogFEData: {},
      idArr: [],
      isShowDel: false,
      handleText: null,
      handleType: null,
      objData: {},
      isShowSub: false,
      courseObj: {
        isShowFile: false,
        title: '请选择导出内容'
      },
      batchCancelObj: {
        isShowFile: false,
        title: '批量取消匹配',
        txtObj: ['全部筛选课程', '选择课程'],
        dialogFEData: {
          width: '400px',
          height: '260px'
        }
      },
      checkList: [],
      switchArr: {
        0:'无',
        1:'有',
      }
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal.name == "courseManagement") {
          // this.formInline.teachingPlanId = newVal.query.id;
          // this.formExport.teachingPlanId = newVal.query.id;
          // this.formExport.tenantId = newVal.query.tenantId;
          // let routeData = this.$route;
          // if (routeData.query.hasOwnProperty("tenantId")) {
          //   this.tenantId = Number(routeData.query.tenantId);
          // }
          this.getCourseArrangeData()
        }
      }
    }
  },
  created() {
    this.getTenantDown()
    this.getProviceEvent()
  },
  methods: {
    handleKeydown(event) {
      if (!this.isShowOut&&!this.isShowCourseware&&!this.isShow&&!this.isResShow&&!this.isShowInfo&&!this.isShowVideo&&!this.isShowFile&&!this.isShowDel&&!this.courseObj.isShowFile&&!this.batchCancelObj.isShowFile&&!this.isShowSub) {
        this.onSubmit()
      }
    },
    changeTen() {
      this.formInline.majorId = null
      this.majorNameData = []
    },
    changeSchEvent() {
      this.formInline.admissionBatch = null;
      this.formInline.majorName = null;
      this.schoolBatchData = []
      this.majorNameData = []
      this.semesterData = []
      this.getGoSchoolData()
      this.getSemesterDownVoData()
    },
    teachingMethod(val) {
      return val.split(",");
    },
    teachingMethodType(val) {
      let arr = val.split(",");
      let text = "";
      let type = {
        0: "录播",
        1: "直播",
        2: "面授"
      };
      for (let item of arr) {
        text += type[item] + " ";
      }
      return text;
    },
    // 編輯課程
    dialogDiyCourseEvent() {
      this.isShowInfo = !this.isShowInfo;
      this.clearInfoEvent();
    },
    handleCourse(row) {
      console.log(row);
      let obj = {
        courseId: row.id,
        teachingPlanId: row.teachingPlanId
      };
      this.$request.getSyscourseInfo(obj, res => {
        if (res.code == 0) {
          console.log(res);
          this.addData = JSON.parse(JSON.stringify(res.data));
          if (!this.addData.teachingMethod) {
            this.addData.teachingMethod = ["0"];
          } else {
            this.addData.teachingMethod = this.addData.teachingMethod.split(
              ","
            );
          }
        }
      });
      this.dialogDiyCourseEvent();
    },
    handleSelectionChange(val) {
      this.idArr = val.map(item => item.planCourseId)
    },
    // 批量删除课程
    batchDelete() {
      this.txtObj = ['全部筛选课程', '已选择课程']
      this.title = '批量删除课程'
      this.feType = 'batchDel'
      this.dialogFEData = {
        width: '400px',
        height: '300px'
      }
      this.downEvent()
    },
    delCourse(row) {
      let obj = {
        courseId: row.id,
        teachingPlanId: row.teachingPlanId
      };
      this.$request.checkCourseHasStu(obj).then(res => {
        if (res.data.code == 0) {
          let txt = res.data.data ? '请注意，当前教学计划课程已有学生学习，删除后学生学习记录将被一并删除，是否确认操作？' : '当前教学计划课程暂未有学生学习，删除后不影响学生学习记录，是否确认删除？'
          this.$confirm(txt, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(() => {
            this.$request.delSyscourse(obj).then(res => {
              console.log(res);
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "删除成功"
                });
                this.getCourseArrangeData();
              }
            });
          });
        }
      })
    },
    enterEvent(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.editInfo();
        } else {
          return false;
        }
      });
    },
    editInfo() {
      this.addData.teachingMethod.sort(function(a, b) {
        return a - b;
      });
      let obj = JSON.parse(JSON.stringify(this.addData));
      obj.teachingPlanId = this.formInline.teachingPlanId;
      obj.teachingMethod = obj.teachingMethod.join(",");
      console.log(this.addData);
      this.$request.updateSyscourse(obj).then(res => {
        if (res.data.code == 0) {
          this.Success("修改成功");
          this.getCourseArrangeData();
          this.dialogDiyCourseEvent();
          this.clearInfoEvent();
        }
      });
    },
    clearInfoEvent() {
      this.addData.courseNature = "";
      this.addData.courseType = "";
      this.addData.credit = "";
      this.addData.courseName = "";
      this.addData.semester = "";
      this.addData.period = "";
      this.addData.passScore = "";
      this.addData.picture = "";
      this.addData.scoreType = "";
      this.addData.teachingMethod = ["0"];
      this.addData.teachingPlanId = "";
    },
    // 获取学校数据
    getTenantDown() {
      this.$request.getTenantDownData({}, res => {
        if (res.code == 0) {
          this.allSchoolData = res.data;
        }
      });
    },
    // 获取入学批次数据
    getGoSchoolData() {
      let obj = {
        tenantId: this.formInline.tenantId
      };
      this.$request.getGoSchool(obj, res => {
        if (res.code == 0) {
          this.schoolBatchData = res.data;
        }
      });
    },
    // 专业数据-层次，批次-搜索区
    getSearchMajor() {
      if(!this.formInline.admissionBatch) return;
      if(!(typeof this.formInline.eduCategory == 'string' || typeof this.formInline.eduCategory == 'number')) return;
      let obj = {
        tenantId: this.formInline.tenantId,
        eduCategory: this.formInline.eduCategory,
        batch: this.formInline.admissionBatch
      }
      this.$request.getMajorList(obj).then(res=>{
        if(res.data.code == 0) {
          this.majorNameData = res.data.data;
        }
      })
    },
    editClick(row) {
      this.isShowPlanEdit = true;
      this.ruleEditForm.id = row.id;
      this.ruleEditForm.name = row.name;
      this.ruleEditForm.status = String(row.status);
    },
    toDoSth: debounce(
      vm => {
        // 这里将当前组件实例当参数传入
        // 就可以使用实例中定义的一些属性、方法
        // 补充一下，这里如果换成非箭头函数的写法，也可以直接访问实例。
        vm.isSearch = true;
        if (vm.types == "courseware") {
          vm.getCourseData(vm.rows);
        } else if (vm.types == "question") {
          vm.getQuesData(vm.rows);
        } else if (vm.types == "ebook") {
          vm.getEbookData(vm.rows);
        } else if (vm.types == "examnum") {
          vm.getExamData(vm.rows);
        }
      },
      200,
      false
    ),
    // 获取课程管理表格数据
    getCourseArrangeData() {
      this.loading = true;
      let formInline = this.formInline;
      this.$request.getSyscourseList(formInline, res => {
        if (res.code == 0) {
          let { records, current, size, total } = res.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total
          };
        }

        this.loading = false;
      });
    },
    // 重置
    resetEvent() {
      Object.assign(this.$data.formInline, this.$options.data().formInline)
      this.courseNature = null;
      this.courseType = null;
      this.schoolBatchData = []
      this.majorNameData = []
      this.semesterData = []
    },
    regEduCategory(row) {
      // if(row.eduCategory) {
      let edu = String(row.eduCategory);
      let val = "";
      switch (edu) {
        case "0":
          val = "专升本";
          break;
        case "1":
          val = "高起本";
          break;
        case "2":
          val = "高起专";
          break;
      }
      return val;
      // }
    },
    regScoreType(row) {
      // if(row.scoreType) {
      let score = String(row.scoreType);
      let val = "";
      switch (score) {
        case "1":
          val = "分值";
          break;
        case "2":
          val = "等级";
          break;
      }
      return val;
      // }
    },
    regCourseNature(row) {
      // 1.选修 2.必修
      let score = String(row.courseNature);
      let val = "";
      switch (score) {
        case "1":
          val = "选修";
          break;
        case "2":
          val = "必修";
          break;
        case "3":
          val = "主干课程";
          break;
      }
      return val;
      // }
    },
    regCourseType(row) {
      //  1.公共基础课 2.专业课 3 专业基础课 4 实践课
      let score = String(row.courseType);
      let val = "";
      switch (score) {
        case "1":
          val = "公共基础课";
          break;
        case "2":
          val = "专业课";
          break;
        case "3":
          val = "专业基础课";
          break;
        case "4":
          val = "实践课";
          break;
      }
      return val;
      // }
    },
    regIsMatching(row) {
      // if(row.isMatching) {
      let match = String(row.isMatching);
      let val = "";
      switch (match) {
        case "0":
          val = "否";
          break;
        case "1":
          val = "是";
          break;
      }
      return val;
      // }
    },
    // 专业模糊查询
    fuzzyQueryEvent(majorName) {
      this.$request.fuzzyQuery({ majorName }, res => {
        if (res.code == 0) {
          this.majorNameData = res.data;
        }
      });
    },
    async picChange() {
      let inputDOM = this.$refs.loadPic;
      let files = inputDOM.files[0];
      let obj = await excelFileUpload(files);
      if (obj) {
        this.fileName = obj.fileName;
        this.showFileName = obj.showFileName;
        this.excelUrl = obj.excelUrl;
      }
      // let ossUrl = await way.uploadOss(OSS, files, 'excel/courseManagement');
    },
    // 导出
    exportEvents() {
      let obj = JSON.parse(JSON.stringify(this.formExport))
      obj.tenantName = this.$route.query.tenantName
      this.$request.exportPlan(obj, res => {
        if (res.code == 0) {
          this.Success("导出成功");
          this.isResShow = true;
          this.isShowOut = false;
        }
      });
    },
    showCourse(type, row) {
      this.newTableData = [];
      this.types = type;
      this.rows = row;
      if (type == "courseware") {
        this.dialogCoursewareDiyData.title = "选择匹配课件";
        // this.kechengName = row.courseName;
        // row.isUseing=row.id
      } else if (type == "coursemate") {
        this.dialogCoursewareDiyData.title = "匹配课程";
        this.formCourseInline.name = row.courseName || "";
        // this.inputKey = row.questionBankName;
      } else if (type == "question") {
        this.dialogCoursewareDiyData.title = "匹配题库";
        // this.inputKey = row.questionBankName;
      } else if (type == "ebook") {
        this.dialogCoursewareDiyData.title = "重新选择匹配教辅材料";
      } else if (type == "examnum") {
        this.dialogCoursewareDiyData.title = "选择学校试卷";
        // this.inputKey = row.coursewareName;
      }

      this.newLoading = true;
      this.isShowCourseware = !this.isShowCourseware;
      this.form.id = row.id;
      this.newForm.courseId = row.id
      this.newForm.teachPlanIds = [row.teachingPlanId]
      if (type == "courseware") {
        this.newForm.coursewareId = row.coursewareId
        this.newForm.coursewareName = row.coursewareName
        this.newForm.type = 1
        this.getCourseData(row);
      } else if (type == "coursemate") {
        this.getCourseNewData(row);
      } else if (type == "question") {
        this.getQuesData(row);
      } else if (type == "ebook") {
        this.newForm.type = 2
        this.getEbookData(row);
      } else if (type == "examnum") {
        this.getExamData(row);
      }
    },

     // 获取学期下拉
     getSemesterDownVoData() {
      this.$request.getSemesterDownVo({tenantId: this.formInline.tenantId}).then((res) => {
        if (res.data.code == 0) {
          this.semesterData = res.data.data
        }
      });
    },
    closeCourse() {
      this.isShowCourseware = !this.isShowCourseware;
      this.clearEvent();
    },
    changeEvent(row, index) {
      if (this.types == "courseware") {
        // this.form.coursewareName = row.coursewareName;
        // this.form.coursewareId = row.id;
        this.$nextTick(() => {
          for (let i = 0; i < this.newTableData.length; i++) {
            let newRow = JSON.parse(JSON.stringify(this.newTableData[i]));
            newRow.isEnable = false;
            this.$set(this.newTableData, i, newRow);
          }
          this.$set(this.newTableData[index], "isEnable", row.isEnable);
          if (!row.isEnable) {
            // 课件全部取消为2
            // this.form.cancel = 2;
            this.newForm.coursewareName = null
            this.newForm.coursewareId = null
          } else {
            // this.form.cancel = null;
            this.newForm.coursewareName = row.coursewareName;
            this.newForm.coursewareId = row.id;
          }
        });
      } else if (this.types == "coursemate") {
        this.form.courseName = row.name;
        this.form.subjectId = row.id;
        this.newTableData.forEach((ite, ind) => {
          let newRow = JSON.parse(JSON.stringify(ite));
          newRow.isEnable = false;
          this.$set(this.newTableData, ind, newRow);
        });
        this.$set(this.newTableData[index], "isEnable", row.isEnable);
        if (!row.isEnable) {
          // 课程全部取消为1
          this.form.cancel = 1;
        } else {
          this.form.cancel = null;
        }
      } else {
        if (this.types == "question") {
          this.questionWay(row, index);
        } else if (this.types == "ebook") {
          this.ebookWay(row, index);
        }
      }
    },
    // question
    questionWay(row, index) {
      if (!row.isUsing) {
        let qId_index = this.questionIdArr.findIndex(item => item == row.id);
        if (qId_index != -1) this.questionIdArr.splice(qId_index, 1);
      } else {
        this.questionIdArr.push(row.id);
      }

      // this.$set(this.newTableData, index, row);
    },
    ebookWay(row, index) {
      this.ebookIdArr.push(row.id);
      this.newTableData.forEach((ite, ind) => {
        let newRow = JSON.parse(JSON.stringify(ite));
        // this.newTableData[ind] = newRow;
        this.$set(this.newTableData, ind, newRow);
      });
      this.$set(this.newTableData[index], "isEnable", Boolean(row.isEnable));
      if (!row.isEnable) {
        let eId_index = this.ebookIdArr.findIndex(item => item == row.id);
        let eName_index = this.ebookNameArr.findIndex(item => item == row.name);
        if (eId_index != -1) this.ebookIdArr.splice(eId_index, 1);
        if (eName_index != -1) this.ebookNameArr.splice(eName_index, 1);
      }
    },
    examWay(row, index) {
      this.examPaperTenantIdArr.push(row.sourceId);
      // if (!row.isUsing) {
      //   let eId_index = this.examPaperTenantIdArr.findIndex(
      //     (item) => item == row.sourceId
      //   );

      //   if (eId_index != -1) this.examPaperTenantIdArr.splice(eId_index, 1);
      // }
      this.$set(this.newTableData, index, row);
    },
    // 保存
    saveEvent() {
      if (this.types == "question") {
        // this.form.questionBankName = this.questionNameArr.join(",");
        this.form.questionBankIds = this.questionIdArr.join(",");
        this.$request.editCourseQues(this.form).then(res => {
          if (res.data.code == 0) {
            this.Success("保存成功");
            this.getCourseArrangeData();
            this.isShowCourseware = !this.isShowCourseware;
            this.clearEvent();
          }
        });
      } else if (this.types == "ebook") {
        // this.form.ebookName = this.ebookNameArr.join(",");
        // this.form.ebookIds = this.ebookIdArr.join(",");
        this.ebookIdArr = [];
        for (let item of this.newTableData) {
          if (item.isEnable) {
            this.ebookIdArr.push(item.id);
            this.ebookNameArr.push(item.name);
          }
        }
        this.newForm.ebookName = this.ebookNameArr.join(",");
        this.newForm.ebookIds = this.ebookIdArr.join(",");
        this.$request.updateTeachingPlan(this.newForm).then(res => {
          if (res.data.code == 0) {
            this.Success("保存成功");
            this.getCourseArrangeData();
            this.isShowCourseware = !this.isShowCourseware;
            this.clearEvent();
          }
        });
      } else if (this.types == "examnum") {
        this.examPaperTenantIdArr = [];
        for (let item of this.newTableData) {
          if (item.isUsing) {
            this.examPaperTenantIdArr.push(item.sourceId);
          }
        }
        this.form.examPaperTenantIds = this.examPaperTenantIdArr.join(",");
        this.$request.editExam(this.form).then(res => {
          if (res.data.code == 0) {
            this.Success("保存成功");
            this.getCourseArrangeData();
            this.isShowCourseware = !this.isShowCourseware;
            this.clearEvent();
          }
        });
      } else if (this.types == "coursemate") {
        this.$request.editCourse(this.form).then(res => {
          if (res.data.code == 0) {
            this.Success("保存成功");
            this.getCourseArrangeData();
            this.isShowCourseware = !this.isShowCourseware;
            this.clearEvent();
          }
        });
      } else {
        this.$request.updateTeachingPlan(this.newForm).then(res => {
          if (res.data.code == 0) {
            this.Success("保存成功");
            this.getCourseArrangeData();
            this.isShowCourseware = !this.isShowCourseware;
            this.clearEvent();
          }
        });
      }
    },
    clearEvent() {
      this.inputKey = "";
      this.form.coursewareId = null;
      this.form.coursewareName = null;
      this.form.ebookIds = null;
      this.form.ebookName = null;
      this.form.id = null;
      this.form.questionBankIds = null;
      this.form.questionBankName = null;
      this.form.courseName = null;
      this.form.subjectId = null;
      this.questionIdArr = [];
      this.questionNameArr = [];
      this.ebookIdArr = [];
      this.examPaperTenantIdArr = [];
      this.ebookNameArr = [];
      this.isSearch = false;
      this.formCourseInline.page = 1;
      this.formCourseInline.pageSize = 10;
    },
    // 获取课件表格数据
    getCourseData(row) {
      let name = this.inputKey || "";
      // let courseName = this.kechengName || "";
      let subjectId = row.subjectId;
      let objRes = {
        name,
        // courseName,
        subjectId
      };
      let obj = {
        ...objRes,
        ...this.formCoursewareInline
      };
      this.$request.getCoursewareList(obj, res => {
        this.newLoading = false;
        if (res.code == 0) {
          let { records, current, size, total } = res.data;
          this.newTableData = records;
          this.coursewareChildMsg = {
            current,
            size,
            total
          };
          this.addSubscript(row, "courseware");
        }
      });
    },
    addNewCoursemate(row) {
      console.log(row.rows.id);
      this.$confirm("确定要新建与原课程名称同名的课程并启用吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        // {courseId:}
        this.$request.addCourse({ courseId: row.rows.id }).then(res => {
          if (res.data.code == 0) {
            this.Success("新增成功");
            this.getCourseArrangeData();
            this.isShowCourseware = !this.isShowCourseware;
          }
        });
      });
    },
    // 获取课程表格数据
    getCourseNewData(row) {
      // console.log(row);
      let obj = {
        ...this.formCourseInline
      };
      this.$request.getCourseList(obj, res => {
        this.newLoading = false;
        if (res.code == 0) {
          let { records, current, size, total } = res.data;
          this.newTableData = records;
          this.courseChildMsg = {
            current,
            size,
            total
          };
          this.addSubscript(row, "coursemate");
        }
      });
    },
    // 获取题库表格数据
    getQuesData(row) {
      // sourceType:0 = 公共  sourceType：1 = 私库
      let title = this.inputKey || "";
      let obj = {
        courseId: row.id,
        title,
        page: 1,
        pageSize: 50,
        sourceType: 0
      };
      this.$request.getQuestionList(obj, res => {
        this.newLoading = false;
        if (res.code == 0) {
          // this.inputKey = this.isSearch ? this.inputKey : row.questionBankName;
          for (let item of res.data) {
            item.isUsing = Boolean(item.isUsing);
            if (item.isUsing) {
              this.questionIdArr.push(item.id);
            }
          }
          this.newTableData = res.data;
          this.addSubscript();
        }
      });
      this.newTablePrivateData = [];
      let obj1 = {
        courseId: row.id,
        title,
        page: 1,
        pageSize: 50,
        sourceType: 1
      };
      this.$request.getQuestionList(obj1, res => {
        this.newLoading = false;
        if (res.code == 0) {
          // this.inputKey = this.isSearch ? this.inputKey : row.questionBankName;
          for (let item of res.data) {
            item.isUsing = Boolean(item.isUsing);
            if (item.isUsing) {
              this.questionIdArr.push(item.id);
            }
          }
          this.newTablePrivateData = res.data;
          this.addSubscript();
        }
      });
    },
    // 获取教辅材料表格数据
    getEbookData(row) {
      let name = this.inputKey || "";
      let obj = {
        courseId: row.id,
        ids: row.ebookIds,
        name,
        page: 1,
        pageSize: 50
      };
      this.$request.getEbookList(obj, res => {
        this.newLoading = false;
        if (res.code == 0) {
          // this.inputKey = this.isSearch ? this.inputKey : row.ebookName;
          //  isEnable
          this.newTableData = res.data;
          this.addSubscript(row, "ebook");
        }
      });
    },
    // 获取试卷表格数据
    getExamData(row) {
      let tenantName = this.inputKey || "";
      let obj = {
        courseId: row.id,
        tenantName,
        page: 1,
        pageSize: 50
      };
      this.$request.getExamPaperList(obj, res => {
        this.newLoading = false;
        if (res.code == 0) {
          // this.inputKey = this.isSearch ? this.inputKey : row.ebookName;

          for (let item of res.data) {
            item.isUsing = Boolean(item.isUsing);
            if (item.sourceId == this.tenantId) {
              item.isUsing = true;
            }
          }
          this.newTableData = res.data;
          this.addSubscript();
        }
      });
    },

    // 题库，课件，教辅材料表格数据添加下标
    addSubscript(row, type) {
      this.newTableData.forEach(item => {
        item.isEnable = false;
        if (type == "courseware") {
          if (item.id == row.coursewareId) {
            item.isEnable = true;
          }
        } else if (type == "ebook") {
          let arr = row.ebookIds.split(",");
          if (arr.includes(String(item.id))) {
            item.isEnable = true;
          }
        } else if (type == "coursemate") {
          if (item.id == row.subjectId) {
            item.isEnable = true;
          }
        }
      });
    },
    dialogCoursewareEvent(val) {
      this.isShowCourseware = val.isShow;
      this.activeQuestion = 0;
      this.activeExamNum = 0;
      this.clearEvent();
    },
    dialogOutEvent(val) {
      this.isShowOut = val.isShow;
      // this.clearFormEvent()
    },
    cancelEvent() {
      this.isShowOut = !this.isShowOut;
    },
    // clearFormEvent() {
    //   this.formExport.admissionBatch = null,
    //   this.formExport.eduCategory = null,
    //   this.formExport.name = null,
    //   this.formExport.page = 1,
    //   this.formExport.pageSize = 20,
    //   this.formExport.tenantId = null
    // },
    // uploadPlan() {
    //   this.isShow = true;
    // },
    exportEvent() {
      this.isShowOut = !this.isShowOut;
    },
    onSubmit() {
      if (!this.formInline.id) {
        this.formInline.id = null;
      }
      if (!this.formInline.subjectId) {
        this.formInline.subjectId = null;
      }
      if (!this.formInline.period) {
        this.formInline.period = null;
      }
      if (this.formInline.id && !way.regNumber(this.formInline.id)) {
        this.Warn("课程编码为数字类型");
        return;
      }
      if (
        this.formInline.subjectId &&
        !way.regNumber(this.formInline.subjectId)
      ) {
        this.Warn("科目id为数字类型");
        return;
      }
      if (this.formInline.period && !way.regNumber(this.formInline.period)) {
        this.Warn("学时为数字类型");
        return;
      }
      this.formInline.page = 1;
      this.formInline.pageSize = 10;
      this.getCourseArrangeData();
    },
    // closeEvent() {
    //   this.isShow = !this.isShow;
    // },
    // 确认上传
    getLocalPic() {
      this.$refs.loadPic.value = "";
      this.$refs.loadPic.click();
    },
    uploadPlan() {
      this.isShow = true;
    },
    dialogDiyEvent(val) {
      this.isShow = val.isShow;
      this.showFileName = "";
      this.fileName = "";
      this.excelUrl = "";
    },
    dialogResDiyEvent(val) {
      this.isResShow = val.isShow;
    },
    // 下载模板
    downLoadMolde() {
      this.$request.getDownUrl({}, res => {
        if (res.code == 0) {
          window.location.href = res.data;
        }
      });
    },
    // 确认上传
    enterUpload() {
      if (!this.excelUrl) {
        this.Warn("请选择excel文件");
        return;
      }
      let obj = {
        url: this.excelUrl,
        fileName: this.fileName,
        tenantId: this.tenantId
      };
      this.$request.importExcel(obj, res => {
        if (res.code == 0) {
          this.excelUrl = "";
          this.fileName = "";
          this.showFileName = "";
          this.isShow = false;
          this.isResShow = true;
        }
      });
    },
    jumpTask() {
      this.$store.commit("notActiveEvent", true);
      sessionStorage.removeItem("key");
      this.$router.push("/Home/taskCenter");
    },
    // 在线预览 课件
    previewEvent(row) {
      // 旧预览方案
      // this.$request.vodShow({ videoId: row.mediaUri }, res => {
      //   if (res.code == 0) {
      //     this.isShowVideo = true;
      //     this.videoData = res.data;
      //     this.vid = row.mediaUri;
      //   }
      // });
      this.$router.push({name: 'viewCoursewareModule', query: {
        courseId: row.id,
      }})
    },
    closeEvent(val) {
      this.isShowVideo = val.isShowVideo;
    },
    // 教辅材料预览
    ebookPreview(row) {
      window.open(row.fileUrl);
    },
    changePage(val) {
      this.childMsg = val;
      this.formInline.page = val.current;
      this.formInline.pageSize = val.size;
      this.getCourseArrangeData();
    },
    // 课程列表分页切换
    changeCoursePage(val) {
      this.courseChildMsg = val;
      this.formCourseInline.page = val.current;
      this.formCourseInline.pageSize = val.size;
      this.getCourseNewData(this.rows);
    },
    // 课件列表分页切换
    changeCourseWarePage(val) {
      this.coursewareChildMsg = val;
      this.formCoursewareInline.page = val.current;
      this.formCoursewareInline.pageSize = val.size;
      this.getCourseData(this.rows);
    },
    resetCourseWare() {
      this.inputKey = null
    },
    downEvent() {
      this.isShowFile = !this.isShowFile;
    },
    enterExportEvent(val) {
      if (val == 1 && this.idArr.length == 0) {
        this.Warn('未勾选数据')
        return;
      }
      if (this.feType == 'batchDel') {
        this.checkBatchDeleteCourseEvent(val)
      }
    },
    // 批量课程删除检测
    checkBatchDeleteCourseEvent(index) {
      // let idsObj = { id:this.$route.query.id, ids: this.idArr }
      let obj = { ...this.formInline}
      obj.ids = index == 1 ? this.idArr : []
      this.objData = obj
      this.$request.checkBatchDeleteCourse(obj).then(res => {
        if (res.data.code == 0) {
          this.handleText = res.data.data ? '请注意，当前所选教学计划课程中已有学生学习，删除后学生学习记录将被一并删除，是否确认操作？' : '当前所选课程暂未有学生学习，删除后不影响学生学习记录，是否确认删除？'
          this.handleType = 'batchDel'
          this.doHandleEvent()
        }
      })
    },
    doHandleEvent() {
      this.isShowDel = !this.isShowDel
    },
    deleStu() {
      if (this.handleType == 'batchDel') {
        this.$request.batchDeleteCourse(this.objData).then((res) => {
          if (res.data.code == 0) {
            this.Success(res.data.msg || '操作成功');
            this.clearSelectionEvent()
            this.getCourseArrangeData()
            this.doHandleEvent()
            this.downEvent()
            this.clearFile()
          }
        });
      }
    },
    clearFile() {
      this.txtObj = null
      this.title = null
      this.feType = null
      this.dialogFEData = {}
    },
    clearSelectionEvent() {
      this.$refs.examTable.clearSelection()
    },
    // 获取省份
    getProviceEvent() {
      this.$request.getPermissionProvinces({}, res => {
        if (res.code == 0) {
          this.proviceData = res.data;
        }
      })
    },
    exportNewEvent() {
      this.courseObj.isShowFile = !this.courseObj.isShowFile
    },
    enterNewExportEvent(val) {
      if (val == 1 && this.idArr.length == 0) {
        this.Warn('请选择要导出的数据')
        return;
      }
      let obj = {};
      obj.isAll = val == 1 ? false : true
      obj.fromFlag = 1
      obj.planCourseIds = val == 1 ? this.idArr : null
      obj.qo = this.formInline
      this.$request.exportPlan(obj, (res) => {
        if (res.code == 0) {
          this.Success(res.msg || "操作成功");
          this.exportNewEvent()
          this.isResShow = true
          this.clearSelectionEvent();
        }
      });
    },
    batchCancelEvent() {
      this.batchCancelObj.isShowFile = !this.batchCancelObj.isShowFile;
      this.checkList = [];
    },
    enterBatchCancelEvent(val) {
      if (val == 1 && this.idArr.length == 0) {
        this.Warn('请选择要导出的数据')
        return;
      }
      let req = {};
      req.allFlag = val == 1 ? false : true;
      req.planCourseIds = this.idArr;
      req.qo = this.formInline;
      req.selectList = this.checkList;
      this.$request.batchCancelCourseware(req).then(res => {
        console.log(res);
        if (res.data.code == 0) {
          this.batchCancelObj.isShowFile = !this.batchCancelObj.isShowFile;
          this.checkList = [];
        }
      })
    },
    closeSub(val) {
      this.isShowSub = val.isShow;
    },
  }
};
</script>

<style lang="less" scoped>
.courseManagement {
  height: 100%;
  .elScroll {
    height: 100%;
    /deep/ .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
  .search_sList {
    background-color: #fff;
    padding: 28px 40px;
  }
  .table_sList {
    box-sizing: border-box;
    padding: 30px 40px;
    margin-top: 10px;
    background-color: #fff;
    .opera_btnArea {
      margin-bottom: 20px;
    }
    /deep/ .el-pagination {
      text-align: right;
    }
  }
  .dialog_upload {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.205);
    .com {
      border-radius: 20px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .closeBtn {
        position: absolute;
        right: -10px;
        top: -10px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .upload_area {
      width: 640px;
      height: 546px;
      .dialog_header {
        height: 70px;
        line-height: 70px;
        text-align: center;
        font-size: 22px;
        font-weight: 600;
        color: #454545;
        border-bottom: 1px solid #f4f7fc;
      }
      .dia_opera {
        display: flex;
        box-sizing: border-box;
        padding: 30px;
        .opera_left,
        .opera_right {
          height: 180px;
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
        }
        .opera_left {
          width: 200px;
          > img {
            width: 51px;
            height: 51px;
          }
          > span {
            font-size: 14px;
            font-weight: 400;
            color: #999999;
          }
        }
        .opera_right {
          width: 188px;
          .bor {
            width: 188px;
            height: 80px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            border: 2px dashed #e1e1e1;
            cursor: pointer;
            > img {
              width: 25px;
              height: 25px;
            }
            > span {
              font-size: 14px;
              font-weight: 400;
              color: #999999;
            }
          }
          .noBorder {
            word-wrap: break-word;
            border: 0 none;
          }
        }
      }
      .dia_tips {
        box-sizing: border-box;
        padding: 0px 40px;
        .d_tips_head {
          color: #454545;
          font-size: 16px;
          font-weight: 600;
        }
        .d_tips_cont {
          color: #777777;
          font-size: 14px;
          p {
            margin-top: 10px;
          }
        }
      }
    }
  }
  .dia_opera_area {
    display: flex;
    box-sizing: border-box;
    padding: 30px;
    .opera_left,
    .opera_right {
      height: 180px;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
    }
    .opera_left {
      width: 200px;
      > img {
        width: 51px;
        height: 51px;
      }
      > span {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
    }
    .opera_right {
      width: 188px;
      .bor {
        width: 188px;
        height: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        border: 2px dashed #e1e1e1;
        cursor: pointer;
        > img {
          width: 25px;
          height: 25px;
        }
        > span {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
        }
      }
      .noBorder {
        word-wrap: break-word;
        border: 0 none;
      }
    }
  }
  .opera_area {
    height: calc(~"100% - 70px");
    box-sizing: border-box;
    padding: 0px 20px;
    position: relative;
    /deep/ .el-tabs__header {
      width: 20%;
      margin-left: 20px;
      .el-tabs__nav-wrap::after {
        background-color: transparent;
      }
    }
    .demo-form-inline {
      padding-top: 20px;
    }
    .search_erea {
      text-align: center;
      /deep/ .el-input {
        width: 300px;
        height: 50px;
        margin: 20px 0px;
        border-radius: 1px solid #e1e1e1;
      }
    }
    .btn_area {
      text-align: right;
      padding: 20px 0px;
      position: absolute;
      bottom: 0;
      right: 20px;
    }
    /deep/ .el-table--scrollable-x .el-table__body-wrapper {
      overflow-x: hidden;
    }
  }
  .dia_operas {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    padding: 30px;
    height: 100%;
    .errIcon {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
  }
  .result_area {
    width: 400px;
    height: 204px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
    span {
      color: #777777;
      font-size: 18px;
    }
    span:nth-of-type(1) {
      margin: 18px 0px;
    }
    .myWork {
      cursor: pointer;
      font-weight: 600;
      color: #1282ff;
    }
  }
  .clickWatch {
    color: #1282ff;
    cursor: pointer;
  }
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.pubToop {
  max-width: 600px;
}
.emptydiv {
  height: 50px;
}
.page-box {
  text-align: center;
}
p.tips {
  font-size: 14px;
  text-align: center;
  padding-bottom: 15px;
  color: #606266;
  span {
    color: #dd1010;
  }
}
.top_opera {
  /deep/ & > .el-form {
    flex: 5!important;
  }
}
/deep/ .ft-area {
  display: flex;
  margin-top: 10px;
  font-size: 14px;
  .ft-title {
    margin-right: 20px;
  }
}
.btn_areas {
  text-align: center;
}
.timeTotal{
  display: flex;
  justify-content: space-around;
  div{
    margin-right: 10px;
  }
}
.timeTotal .el-input{
  width: 65px;
}
</style>
