<template>
  <div class="teachPlanManage">
    <el-scrollbar class="elScroll">
      <template v-if="$route.name=='teachPlanManage'">
        <div class="search_sList">
          <div class="top_opera">
            <el-form
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
              label-width="100px"
            >
              <el-form-item label="选择学校">
                <el-select
                  v-model="formInline.tenantId"
                  placeholder="选择学校"
                  filterable
                  @change="changeTen"
                >
                  <el-option
                    v-for="item in allSchoolData"
                    :key="item.tenantId"
                    :label="item.tenantName"
                    :value="item.tenantId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="批次">
                <el-select
                  v-model="formInline.admissionBatch"
                  filterable
                  placeholder="批次"
                  clearable
                >
                  <el-option
                    v-for="item in schoolBatchData"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="层次">
                <el-select
                  v-model="formInline.eduCategory"
                  placeholder="层次"
                  clearable
                >
                  <el-option v-for="(item, key, index) in $common.getEduCategory" :key="index" :label="item" :value="key"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="专业">
                <el-select
                  v-model="formInline.majorName"
                  placeholder="请选择"
                  @focus="getMajorDataEvent"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in majorNameData"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item label="教学点">
                  <el-select v-model="formInline.stationId" placeholder="教学点">
                    <el-option
                      v-for="item in correspondenceStation"
                      :key="item.stationsId"
                      :label="item.stationsName"
                      :value="item.stationsId">
                    </el-option>
                  </el-select>
                </el-form-item> -->
              <el-form-item label="教学计划名称">
                <el-input
                  v-model="formInline.name"
                  placeholder="教学计划名称"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="教学计划状态">
                <el-select
                  v-model="formInline.status"
                  placeholder="请选择活动区域"
                >
                  <el-option v-for="(item, key) in $common.teachPlanStatusData" :key="key" :label="item" :value="Number(key)"></el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item label="课程安排">
                  <el-input v-model="formInline.courseName" placeholder="课程安排" clearable></el-input>
                </el-form-item> -->
            </el-form>
            <div class="btn_area">
              <el-button class="mainBtn" @click="onSubmit" plain>搜索</el-button>
              <el-button type="info" plain @click="resetEvent">重置</el-button>
            </div>
          </div>
        </div>
        <div class="table_sList">
          <div class="opera_btnArea">
            <!-- <el-button
              class="mainBtn"
              @click="uploadPlan"
              plain
              v-allow="'sys_teach_plan_add'"
              >+上传教学计划</el-button
            > -->
            <el-button
              class="mainBtn"
              @click="exportEvent"
              plain
              v-allow="'sys_teach_plan_export'"
              >导出教学计划</el-button
            >
          </div>
          <el-table
            :data="tableData"
            stripe
            border
            ref="examTable"
            style="width: 100%"
            align="center"
            row-key="id"
            @selection-change="handleSelectionChange"
          >
            <template slot="empty">
              <div v-loading="loading" class="emptydiv">暂无数据</div>
            </template>
            <!-- <el-table-column label="序号" align="center" width="80">
              <template slot-scope="scope">
                {{
                  (formInline.page - 1) * formInline.pageSize + scope.$index + 1
                }}
              </template>
            </el-table-column> -->
            <el-table-column
              align="center"
              type="selection"
              :reserve-selection="true"
              width="40"
            >
            </el-table-column>
            <el-table-column
              prop="province"
              label="省份"
              align="center"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="tenantName"
              label="学校"
              align="center"
              width="180"
            >
              <template slot-scope="scope">
                <el-tooltip
                  :content="scope.row.tenantName"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                >
                  <div class="ellipsis">{{ scope.row.tenantName }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <!-- <el-table-column
                prop="stationName"
                label="教学点"
                align="center"
                width="120">
              </el-table-column> -->
            <el-table-column
              prop="admissionBatch"
              label="入学批次"
              align="center"
              width="140"
            >
            </el-table-column>
            <el-table-column
              prop="educationalSystem"
              label="学制"
              align="center"
              width="140"
            >
            </el-table-column>
            <el-table-column
              prop="eduCategory"
              label="层次"
              align="center"
              width="140"
              :formatter="regEduCategory"
            >
            </el-table-column>
            <el-table-column
              prop="majorName"
              label="专业"
              align="center"
              width="180"
            >
              <template slot-scope="scope">
                <el-tooltip
                  :content="scope.row.majorName"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                >
                  <div class="ellipsis">{{ scope.row.majorName }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="教学计划名称" align="center">
              <template slot-scope="scope">
                <el-tooltip
                  :content="scope.row.name"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                >
                  <div class="ellipsis">{{ scope.row.name }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="status"
              label="状态"
              align="center"
              width="120"
            >
              <template slot-scope="scope">
                {{ scope.row.status == 1 ? "禁用" : "可用" }}
              </template>
            </el-table-column>
            <el-table-column prop="" label="课程安排" align="center" width="140">
              <template slot-scope="scope">
                <el-button size="small" type="text" @click="jumpEvent(scope.row)"
                  >课程安排</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="100"
              align="center"
            >
              <template slot-scope="scope">
                <el-button
                  @click="editClick(scope.row)"
                  type="text"
                  size="small"
                  v-allow="'sys_teach_plan_edit'"
                  >编辑</el-button
                >
                <!-- <el-popconfirm
                  @confirm="handleClick(scope.row)"
                  confirm-button-text="好的"
                  cancel-button-text="不用了"
                  icon="el-icon-info"
                  icon-color="red"
                  title="这是一段内容确定删除吗？"
                > -->
                <el-button
                  @click="deleteEvent(scope.row)"
                  type="text"
                  size="small"
                  v-allow="'sys_teach_plan_del'"
                  >删除</el-button
                >
                <!-- </el-popconfirm> -->
              </template>
            </el-table-column>
          </el-table>
          <Pagination @changePage="changePage" :childMsg="childMsg"></Pagination>
        </div>
        <Keydown @keydownEvent='handleKeydown'></Keydown>
      </template>
      <template v-else>
        <publicChildren></publicChildren>
      </template>
    </el-scrollbar>
    <dialogDiy
      :isShow="isShow"
      :dialogDiyData="dialogDiyData"
      @dialogEvent="dialogDiyEvent"
    >
      <div slot="dialogContent">
        <div class="dia_opera_plan">
          <div class="opera_left">
            <img src="@/assets/img/file.png" />
            <span>教学计划样例</span>
            <el-button class="mainBtn" @click="downLoadMolde"
              >点击下载</el-button
            >
          </div>
          <div class="opera_right">
            <div class="bor" @click="getLocalPic" v-if="!excelUrl">
              <img src="@/assets/img/adds.png" />
              <span>教学计划样例</span>
            </div>
            <div class="bor noBorder" @click="getLocalPic" v-else>
              {{ showFileName }}
            </div>
            <el-button class="mainBtn" @click="enterUpload">确认上传</el-button>
          </div>
        </div>
        <div class="dia_tips">
          <div class="d_tips_head">注意事项</div>
          <div class="d_tips_cont">
            <p>上传的文件需要符合以下要求</p>
            <p>1.文件中包含的行数需小于5000行</p>
            <p>
              2.教学计划文件必须是有效的Excel文件，也就是说必须是用Excel可以打开的以".xlsx"为扩展名的文件。
            </p>
            <p>
              3.文件内容的第一行为列的标题，并且列的顺序不能变
            </p>
            <p>4. 详细说明，详见【教学计划上传样例】。</p>
          </div>
        </div>
      </div>
    </dialogDiy>
    <dialogDiy
      :isShow="isResShow"
      :dialogDiyData="dialogResDiyData"
      @dialogEvent="dialogResDiyEvent"
    >
      <div slot="dialogContent" class="result_area">
        <img src="@/assets/img/enter.png" alt="" />
        <span>您的请求已提交</span>
        <span
          >稍后请在
          <span @click="jumpTask" class="myWork">我的任务</span>
          中查看处理结果！</span
        >
      </div>
    </dialogDiy>
    <dialogDiy
      :isShow="isShowPlanEdit"
      :dialogDiyData="dialogPlanDiyData"
      @dialogEvent="dialogPlanDiyEvent"
    >
      <div class="edit_area" slot="dialogContent">
        <el-form
          :model="ruleEditForm"
          :rules="editRules"
          ref="ruleEditForm"
          label-width="110px"
          class="demo-ruleForm"
        >
          <el-form-item label="教学计划名称" prop="name">
            <el-input maxlength="30" v-model="ruleEditForm.name"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-select
              v-model="ruleEditForm.status"
              placeholder="请选择活动区域"
            >
              <el-option label="可用" value="0"></el-option>
              <el-option label="禁用" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="editEvent('ruleEditForm')"
              >确 认</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </dialogDiy>

    <!-- 批量导出教学计划 -->
    <!-- <dialogDiy
      :isShow="isShowOut"
      :dialogDiyData="dialogOutDiyData"
      @dialogEvent="dialogOutEvent"
    >
      <div slot="dialogContent" class="dia_operas">
        <img class="errIcon" src="@/assets/img/warn.png" alt="" />
        <p>是否导出当前教学计划</p>
        <div class="btn_area">
          <el-button class="mainBtn" @click="exportEvents" plain
            >导出</el-button
          >
        </div>
      </div>
    </dialogDiy> -->
    <fileExportNew
      :isShowFile="isShowFile"
      :title="title"
      @closeFile="downEvent"
      @enter="enterExportEvent"
    ></fileExportNew>

    <input
      style="display: none"
      type="file"
      @change="picChange()"
      ref="loadPic"
      accept=" application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    />

    <handle
      :isShow="isShowDel"
      :handleText="handleText"
      @handlesEvent="doHandleEvent"
    >
      <div class="btn_area" slot="handle_btn">
        <el-button @click="doHandleEvent">关闭</el-button>
        <el-button type="primary" @click="deleStu">确定</el-button>
      </div>
    </handle>
    
  </div>
</template>

<script>
import handle from 'components/commonModule/handle'
import Pagination from "components/Pagination"
import dialogDiy from "components/commonModule/dialogDiy"
import { excelFileUpload } from "@/api/fileUpload"
import fileExportNew from 'components/commonModule/fileExportNew'
import publicChildren from "components/publicChildren"
import way from "@/api/encapsulation"
const OSS = require("ali-oss");
export default {
  name: "teachPlanManage",
  components: {
    handle,
    Pagination,
    dialogDiy,
    fileExportNew,
    publicChildren,
  },
  data() {
    return {
      formInline: {
        fromFlag:2,
        admissionBatch: null,
        // courseName: '',
        eduCategory: null,
        majorCode: null,
        majorName: null,
        name: null,
        page: 1,
        pageSize: 10,
        // stationId: '',
        tenantId: null,
        status: null,
      },
      tableData: [],
      loading: false,
      childMsg: {
        current: 1,
        size: 10,
        total: 1,
      },
      isShow: false,
      isResShow: false,
      isShowPlanEdit: false,
      dialogDiyData: {
        title: "上传教学计划",
        sizeStyle: { width: "640px", height: "546px" },
      },
      dialogResDiyData: {
        title: "",
        sizeStyle: { width: "400px", height: "204px" },
      },
      dialogPlanDiyData: {
        title: "编辑",
        sizeStyle: { width: "450px", height: "280px" },
      },
      ruleEditForm: {
        name: "",
        status: "0",
        id: "",
      },
      editRules: {
        name: [{ required: true, message: "请输入活动名称", trigger: "blur" }],
      },
      visible: true,
      allSchoolData: [],
      correspondenceStation: [],
      excelUrl: "",
      fileName: "",
      showFileName: "",
      schoolBatchData: [],
      majorNameData: [],
      isShowOut: false,
      dialogOutDiyData: {
        title: "",
        sizeStyle: { width: "400px", height: "234px" },
      },
      formExport: {
        teachingPlanId: null,
        majorName: null,
        admissionBatch: null,
        eduCategory: null,
        name: null,
        page: 1,
        pageSize: 20,
        tenantId: null,
      },
      tenantName: null,
      isShowDel: false,
      handleText: null,
      rowData: null,
      handleType: null,
      isHasStu: false,
      isShowFile: false,
      title: null,
      txtObj: [],
      idArr: [],
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        // console.log(newVal,oldVal)
        if (newVal.name == "teachPlanManage") {
          this.getTeachPlanData();
          this.getTenantDown();
        }
      }
    }
  },
  methods: {
    handleKeydown(event) {
      if (!this.isShow&&!this.isResShow&&!this.isShowPlanEdit&&!this.isShowFile&&!this.isShowDel) {
        this.onSubmit()
      }
    },
    // 获取学校数据
    getTenantDown() {
      this.$request.getTenantDownData({}, (res) => {
        if (res.code == 0) {
          this.allSchoolData = res.data;
        }
      });
    },
    // 专业数据
    getMajorDataEvent() {
      if (!this.formInline.tenantId) {
        this.Warn('请选择学校')
        return;
      }
      let tenantId = this.formInline.tenantId;
      this.$request.getMajorData({ tenantId }, (res) => {
        if (res.code == 0) {
          this.majorNameData = res.data;
        }
      });
    },
    // 专业模糊查询
    fuzzyQueryEvent(majorName) {
      this.$request.fuzzyQuery({ majorName }, (res) => {
        if (res.code == 0) {
          this.majorNameData = res.data;
        }
      });
    },
    // 获取入学批次数据
    getGoSchoolData() {
      if (!this.formInline.tenantId) {
        this.Warn('请选择学校')
        return;
      }
      let obj = {
        tenantId: this.formInline.tenantId
      };
      this.$request.getGoSchool(obj, (res) => {
        if (res.code == 0) {
          this.schoolBatchData = res.data;
        }
      });
    },
    // 获取教学点数据
    getTheDropDownBoxData() {
      let obj = {
        tenantId: this.formInline.tenantId,
      };
      this.$request.getTheDropDownBoxs(obj, (res) => {
        if (res.code == 0) {
          this.correspondenceStation = res.data;
        }
      });
    },
    changeTen() {
      this.formInline.admissionBatch = null
      this.formInline.majorName = null;
      this.schoolBatchData = []
      this.majorNameData = []
      this.getGoSchoolData();
      this.getMajorDataEvent();
      this.getTenantName()
    },
    getTenantName() {
      let index = this.allSchoolData.findIndex(item => item.tenantId == this.formInline.tenantId)
      this.tenantName = this.allSchoolData[index].tenantName
    },
    // 获取教学计划表格数据
    getTeachPlanData() {
      let obj = this.formInline;
      this.loading = true;
      this.$request.getList(obj, (res) => {
        if (res.code == 0) {
          let { records, current, size, total } = res.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total,
          };
        }

        this.loading = false;
      });
    },
    // 重置
    resetEvent() {
      Object.assign(this.$data.formInline, this.$options.data().formInline)
      this.schoolBatchData = []
      this.majorNameData = []
    },
    regEduCategory(row) {
      let edu = String(row.eduCategory);
      let val = this.$common.getEduCategory[edu];
      return val;
    },
    jumpEvent(row) {
      this.$router.push({
        name: "courseArrange",
        query: {
          id: row.id,
          headTitle: "课程安排",
          tenantId: row.tenantId,
          tenantName: row.tenantName
        },
      });
    },
    deleteEvent(row) {
      let obj = {
        id: row.id
      }
      this.$request.checkCourseDel(obj).then(res => {
        if (res.data.code == 0) {
          this.handleText = !res.data.data ? '该教学计划已导入学生，无法删除' : '该教学计划暂无学生导入，是否确认删除'
          this.handleType = 'delete'
          this.rowData = row
          this.isHasStu = !res.data.data
          this.doHandleEvent()
        }
      })
    },
    // 删除
    handleClick() {
      this.$request.deleteList({ id: this.rowData.id }, (res) => {
        // console.log(res)
        if (res.code == 0) {
          this.getTeachPlanData()
          this.Success("删除成功")
          this.doHandleEvent()
        }
      });
    },
    doHandleEvent() {
      this.isShowDel = !this.isShowDel
    },
    deleStu() {
      if (this.handleType == 'delete') {
        if(!this.isHasStu)
          this.handleClick()
        else
          this.doHandleEvent()
      }
    },
    uploadPlan() {
      this.isShow = true;
    },
    exportEvent() {
      this.title = '请选择导出教学计划范围'
      this.downEvent()
    },
    dialogOutEvent(val) {
      this.isShowOut = val.isShow;
      // this.clearFormEvent()
    },
    // 导出
    exportEvents() {
      let obj = JSON.parse(JSON.stringify(this.formInline));
      obj.tenantName = this.tenantName
      this.$request.exportPlan(obj, (res) => {
        if (res.code == 0) {
          this.Success("导出成功");
          this.isResShow = true;
          this.isShowOut = false;
          this.clearFormEvent();
        }
      });
    },
    clearFormEvent() {
      this.formExport.teachingPlanId = null;
      this.formExport.majorName = null;
      this.formExport.admissionBatch = null;
      this.formExport.eduCategory = null;
      this.formExport.name = null;
      this.formExport.page = 1;
      this.formExport.pageSize = 20;
    },
    async picChange() {
      let inputDOM = this.$refs.loadPic;
      let files = inputDOM.files[0];
      let obj = await excelFileUpload(files);
      if (obj) {
        this.fileName = obj.fileName;
        this.showFileName = obj.showFileName;
        this.excelUrl = obj.excelUrl;
      }
      // let ossUrl = await way.uploadOss(OSS, files, 'excel/teachPlan');
    },
    // 确认上传
    enterUpload() {
      if (!this.excelUrl) {
        this.Warn("请选择excel文件");
        return;
      }
      let obj = {
        url: this.excelUrl,
        fileName: this.fileName,
        tenantId: this.formInline.tenantId,
      };
      this.$request.importExcel(obj, (res) => {
        if (res.code == 0) {
          this.excelUrl = "";
          this.fileName = "";
          this.showFileName = "";
          this.isShow = false;
          this.isResShow = true;
        }
      });
    },
    jumpTask() {
      this.$store.commit("notActiveEvent", true);
      sessionStorage.removeItem("key");
      this.$router.push("/Home/taskCenter");
    },
    onSubmit() {
      this.formInline.page = 1;
      this.formInline.pageSize = 10;
      this.getTeachPlanData();
    },
    // 下载模板
    downLoadMolde() {
      this.$request.getDownUrl({}, (res) => {
        if (res.code == 0) {
          window.location.href = res.data;
        }
      });
    },
    getLocalPic() {
      this.$refs.loadPic.value = "";
      this.$refs.loadPic.click();
    },
    dialogDiyEvent(val) {
      this.excelUrl = "";
      this.fileName = "";
      this.showFileName = "";
      this.isShow = val.isShow;
    },
    dialogResDiyEvent(val) {
      this.isResShow = val.isShow;
    },
    dialogPlanDiyEvent(val) {
      this.isShowPlanEdit = val.isShow;
    },
    editClick(row) {
      this.isShowPlanEdit = true;
      this.ruleEditForm.id = row.id;
      this.ruleEditForm.name = row.name;
      this.ruleEditForm.status = String(row.status);
    },
    editEvent(formName) {
      let ruleEditForm = this.ruleEditForm;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$request.updateList(ruleEditForm, (res) => {
            if (res.code == 0) {
              this.Success("修改成功");
              this.getTeachPlanData();
              this.isShowPlanEdit = false;
            }
          });
        } else {
          return false;
        }
      });
    },
    changePage(val) {
      this.childMsg = val;
      this.formInline.page = val.current;
      this.formInline.pageSize = val.size;
      this.getTeachPlanData();
    },
    handleSelectionChange(val) {
      let idArr = val.map(item => item.id)
      this.idArr = idArr;
    },
    downEvent() {
      this.isShowFile = !this.isShowFile;
    },
    enterExportEvent(val) {
      console.log(val)
      if (val == 1 && this.idArr.length == 0) {
        this.Warn('未勾选数据')
        return;
      }
      let obj = this.$lodash.cloneDeep(this.formInline)
      obj.isAll = val == 1 ? false : true
      obj.teachingPlanIds = val == 1 ? this.idArr : null
      this.$request.exportPlan(obj, (res) => {
        if (res.code == 0) {
          this.Success(res.msg || "操作成功");
          this.downEvent()
          this.isResShow = true
          this.clearFormEvent();
          this.clearSelectionEvent();
        }
      });
    },
    clearSelectionEvent() {
      this.$refs.examTable.clearSelection()
    },
  },
};
</script>

<style lang="less" scoped>
.teachPlanManage {
  height: 100%;
  .elScroll {
    height: 100%;
    /deep/ .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
  .search_sList {
    background-color: #fff;
    padding: 28px 40px;
    .top_opera {
      display: flex;
      /deep/ .el-form {
        flex: 5;
      }
      .btn_area {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .table_sList {
    box-sizing: border-box;
    padding: 30px 40px;
    margin-top: 10px;
    background-color: #fff;
    .opera_btnArea {
      margin-bottom: 20px;
    }
    /deep/ .el-pagination {
      text-align: right;
    }
  }
  .result_area {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
    span {
      color: #777777;
      font-size: 18px;
    }
    span:nth-of-type(1) {
      margin: 18px 0px;
    }
    .myWork {
      cursor: pointer;
      font-weight: 600;
      color: #1282ff;
    }
  }
  .opera_area {
    height: calc(~"100% - 70px");
    box-sizing: border-box;
    padding: 0px 20px;
    position: relative;
    .demo-form-inline {
      padding-top: 20px;
    }
    .search_erea {
      text-align: center;
      /deep/ .el-input {
        width: 300px;
        height: 50px;
        margin: 20px 0px;
        border-radius: 1px solid #e1e1e1;
      }
    }
    .btn_area {
      text-align: right;
      padding: 20px 0px;
      position: absolute;
      bottom: 0;
      right: 20px;
    }
  }
  .dia_operas {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    padding: 30px;
    height: 100%;
    .errIcon {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
  }
  .edit_area {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.pubToop {
  max-width: 600px;
}
.emptydiv {
  height: 50px;
}
.dia_opera_plan {
  display: flex!important;
  box-sizing: border-box!important;
  padding: 30px!important;
  .opera_left,
  .opera_right {
    height: 180px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .opera_left {
    width: 200px;
    > img {
      width: 51px;
      height: 51px;
    }
    > span {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
    }
  }
  .opera_right {
    width: 188px;
    .bor {
      width: 188px;
      height: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      border: 2px dashed #e1e1e1;
      cursor: pointer;
      > img {
        width: 25px;
        height: 25px;
      }
      > span {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
    }
    .noBorder {
      word-wrap: break-word;
      border: 0 none;
    }
  }
}
.dia_tips {
  box-sizing: border-box;
  padding: 0px 40px;
  .d_tips_head {
    color: #454545;
    font-size: 16px;
    font-weight: 600;
  }
  .d_tips_cont {
    color: #777777;
    font-size: 14px;
    p {
      margin-top: 10px;
    }
  }
}
</style>