<template>
  <dialogDiy :isShow="isResShow" :dialogDiyData="dialogResDiyData"
    @dialogEvent="dialogResDiyEvent">
    <div slot="dialogContent" class="result_areas">
      <img src="../../assets/img/enter.png" alt=""/>
      <span>您的请求已提交</span>
      <span>稍后请在 <span class="myWork" @click="jumpTask">我的任务</span> 中查看处理结果！</span>
    </div>
  </dialogDiy>
</template>

<script>
import dialogDiy from './dialogDiy'
export default {
  components: {
    dialogDiy
  },
  props: ['isShowSub'],
  data() {
    return {
      isResShow: false,
      dialogResDiyData: {
        title: '',
        sizeStyle: {width: '400px',height: '204px'}
      },
    }
  },
  watch: {
    isShowSub: {
      immediate: true,
      handler(newVal, oldVal) {
        this.isResShow = newVal;
      }
    },
  },
  methods: {
    dialogResDiyEvent(val) {
      this.isResShow = val.isShow;
      this.$emit('closeSub', val)
    },
    jumpTask() {
      this.$store.commit('notActiveEvent', true);
      sessionStorage.removeItem('key')
      this.$router.push('/Home/taskCenter')
    },
  },
}
</script>

<style lang="less">
  .result_areas {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
    span {
      color: #777777;
      font-size: 18px;
    }
    span:nth-of-type(1) {
      margin: 18px 0px;
    }
    .myWork {
      cursor: pointer;
      font-weight: 600;
      color: #1282FF;
    }
  }
</style>